<template>
  <v-app id="ajustes">
    <v-main>
      <v-container
        fluid
        fill-height
        class="grey lighten-4"
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs10
            sm4
            md3
            class="text-center"
          >
            <v-progress-circular
              :size="70"
              :width="7"
              indeterminate
              color="primary"
              class="mb-4"
            ></v-progress-circular>

            <h1 class="blue--text text--darken-3 text-h6">Cargando aplicación</h1>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  methods: {
    fetchAjustes() {
      return this.$http.get('ajustes')
        .then((response) => response.data)
        .catch((error) => {
          this.$eventHub.$emit('http-error', error);
          throw error;
        });
    },
  },

  created() {
    this.fetchAjustes().then((ajustes) => {
      localStorage.setItem('ajustes', JSON.stringify(ajustes));

      this.$store.state.ajustes = ajustes
      this.$router.replace({name: 'login'});
    });
  }
};
</script>
